<div *ngIf="this.loading; else orderDetails" class="loading-spinner-overlay">
    <div class="loading-spinner-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #orderDetails>
    <div class="padding full-height">
        <mat-card>
            <mat-card-header>
                <div class="header-flex-container">
                    <mat-card-title> {{ orderTypeName }} </mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-container padding-top-15">
                    <span class="flex-item-2">Reference Number:</span>
                    <span class="flex-item-2">{{ order.referenceNumber }}</span>

                    <span class="flex-item-2">Date Requested:</span>
                    <span class="flex-item-2">{{ order.dateRequested | pacificDate}}</span>
                </div>
                <div class="flex-container">
                    <span class="flex-item-2">Status:</span>
                    <span class="flex-item-2"><b>{{ order.orderStatusTypeID }}</b></span>

                    <span class="flex-item-2">Status Updated:</span>
                    <span class="flex-item-2">{{ order.orderStatusUpdatedDate | pacificDate}}</span>
                </div>
                <div class="flex-container">
                    <span class="flex-item-2">Jurisdiction:</span>
                    <span class="flex-item-2">{{ order.jurisdiction }}</span>

                    <span class="flex-item-2">Country:</span>
                    <span class="flex-item-2">{{ order.country }}</span>
                </div>
                <div class="flex-container" *ngIf="order.lien?.qcFormTypeID">
                    <span class="flex-item-2">Form:</span>
                    <span class="flex-item-2">{{ order.lien!.qcFormTypeID }}</span>

                    <span class="flex-item-2"> </span>
                    <span class="flex-item-2"> </span>
                </div>

                <lib-order-search-criteria></lib-order-search-criteria>

                <app-search-result [order]="order"></app-search-result>
                
                <div class="flex-container" *ngIf="uiConfiguration?.hasReferenceRegistrationInformation">
                    <h3>Existing Registration Details</h3>
                    <lib-existing-registration-details
                    [uiConfiguration]="uiConfiguration!.referenceRegistrationInformationUIConfiguration!">
                    </lib-existing-registration-details>
                </div>

                <div class="flex-container" *ngIf="isDischarge()">
                    <app-discharge-details [order]="order" class="flex-item"></app-discharge-details>
                </div>

                <div class="padding-top-bottom-15" *ngIf="order?.lien">
                    <mat-divider></mat-divider>
                    <h3>Lien Details</h3>
                    <div>
                        <lib-lien-details [uiConfiguration]="uiConfiguration?.lienDetailsConfiguration!"></lib-lien-details>

                        <div *ngFor="let result of order.lien?.registrationResults ?? []" class="margin-bottom-25">
                            <h4 *ngIf="uiConfiguration?.amendmentConfiguration?.hasMultipleForms" class="no-margin">{{ result.registryForm?.formFull ?? '' }} Results</h4>
                            <lib-lien-results  [results]="result" class="flex-item"></lib-lien-results>
                            <lib-document-download [documentID]="result.document?.id"
                            [documentName]="'Verification Statement'"></lib-document-download>
                        </div>
                    </div>
                </div>

                <!-- Secured Parties -->
                <div class="flex-container" *ngIf="!isSearchOrder() && uiConfiguration?.hasSecuredParties">
                    <lib-secured-party-list [title]="securedPartiesTitle" [observableParties$]="securedParties"
                        [isEditable]="false" [uiConfiguration]="uiConfiguration!.securedPartiesConfiguration!"
                        class="flex-item-no-padding">
                    </lib-secured-party-list>
                </div>
                <!-- Debtors -->
                <div class="flex-container" *ngIf="!isSearchOrder() && uiConfiguration?.hasDebtors">
                    <lib-debtor-list [title]="debtorsTitle" [observableParties$]="debtors" [isEditable]="false"
                        [uiConfiguration]="uiConfiguration!.debtorsConfiguration!" class="flex-item-no-padding">
                    </lib-debtor-list>
                </div>
                <!-- Dealers -->
                <div class="flex-container" *ngIf="uiConfiguration?.hasDealers">
                    <lib-dealer-list [title]="dealersTitle" [observableParties$]="dealers" [isEditable]="false"
                        [uiConfiguration]="uiConfiguration!.dealersConfiguration!" class="flex-item-no-padding">
                    </lib-dealer-list>
                </div>

                <div class="padding-top-bottom-15" *ngIf="order?.lien">
                    <lib-general-collateral [uiConfiguration]="uiConfiguration?.collateralConfiguration?.generalCollateralConfiguration"></lib-general-collateral>
                </div>

                <!-- Assets -->
                <div class="flex-container" *ngIf="uiConfiguration!.collateralConfiguration?.hasAssets">
                    <app-asset-list [order]="order" [uiConfiguration]="uiConfiguration!.collateralConfiguration!.assetConfiguration!" class="flex-item">
                    </app-asset-list>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</ng-template>