import { Component } from '@angular/core';
import { ABLienOrderForUpdate, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseHistoricalLienDetailsComponent } from '../base-historical-lien-details.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-historical-ab-lien-details',
  templateUrl: './historical-ab-lien-details.component.html',
  styleUrls: ['./historical-ab-lien-details.component.css']
})
export class HistoricalABLienDetailsComponent extends BaseHistoricalLienDetailsComponent {
  protected courtOrderError$ = new BehaviorSubject<string | null | undefined>(null);
  protected otherChangesError$ = new BehaviorSubject<string | null | undefined>(null);
  protected vehicleReleasedDateError$ = new BehaviorSubject<string | null | undefined>(null);
  protected workLastCompletedDateError$ = new BehaviorSubject<string | null | undefined>(null);
  protected partsProvidedDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  protected override buildForm(): FormGroup {
    const form = this.formBuilder.group({
      amount: [this.order.lien?.amount],
      trustIndenture: [this.order.lien?.trustIndenture ?? false],
      additionalInformation: [this.order.lien?.additionalInformation]
    }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showCourtOrder) {
      form.addControl('courtOrder', new FormControl(this.order.lien?.courtOrder));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showOtherChanges) {
      form.addControl('otherChanges', new FormControl(this.order.lien?.otherChanges));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showStillHavePosession) {
      form.addControl('stillHavePosession', new FormControl(this.order.lien?.stillHavePosession ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showVehicleReleasedDate) {
      form.addControl('vehicleReleasedDate', new FormControl(this.order.lien?.vehicleReleasedDate));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showWorkLastCompletedDate) {
      form.addControl('workLastCompletedDate', new FormControl(this.order.lien?.workLastCompletedDate));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPartsProvidedDate) {
      form.addControl('partsProvidedDate', new FormControl(this.order.lien?.partsProvidedDate));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', new FormControl(this.order.lien?.additionalInformation));
    }

    return form;
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.courtOrderError$.next(errors?.filter(error => error.path.includes('/courtorder'))?.at(0)?.userFriendlyMessage);
    this.otherChangesError$.next(errors?.filter(error => error.path.includes('/otherchanges'))?.at(0)?.userFriendlyMessage);
    this.vehicleReleasedDateError$.next(errors?.filter(error => error.path.includes('/vehiclereleaseddate'))?.at(0)?.userFriendlyMessage);
    this.workLastCompletedDateError$.next(errors?.filter(error => error.path.includes('/worklastcompleteddate'))?.at(0)?.userFriendlyMessage);
    this.partsProvidedDateError$.next(errors?.filter(error => error.path.includes('/partsprovideddate'))?.at(0)?.userFriendlyMessage);
  }
}