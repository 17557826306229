import { Injectable } from '@angular/core';
import { Stepper } from '../../../interfaces/stepper';
import { Order, eOrderStatusType, eOrderType } from 'reg-hub-common';
import { instantiate } from './step-mapper';
import { BehaviorSubject } from 'rxjs';
import { NotFoundStepper } from './steps/not-found-stepper';
import { ValidationRule } from '../../../interfaces/validation-rule';


@Injectable({
  providedIn: 'root'
})
export class StepperService {

  private stepperSubject = new BehaviorSubject<Stepper | null>(null);
  public stepper$ = this.stepperSubject.asObservable();

  private validationRulesSubject = new BehaviorSubject<ValidationRule[] | null>(null);
  public validationRules$ = this.validationRulesSubject.asObservable();
  
  constructor() { }

  updateStepper(newStepper: Stepper) {
    this.stepperSubject.next(newStepper);
  }

  updateValidationRules(newValidationRules: ValidationRule[]) {
    this.validationRulesSubject.next(newValidationRules);
  }

  public initializeSteps(order: Order): Stepper {

    var governingStepperClass = this.buildClassReference(order);
    
    try {
      const stepper = instantiate(governingStepperClass);
      // this.updateStepper(stepper);
      return stepper;
    }
    catch (error) {
      return new NotFoundStepper();
    }
  }

  buildClassReference(order: Order): string {
    if ((order.jurisdiction ?? "") == "" || order.orderTypeID?.toString() == eOrderType.notSet.toString()) {
      return 'base-info-stepper';
    }

    var classReference = `${order.jurisdiction.toLowerCase()}-${order.orderTypeID.toLowerCase()}-stepper`

    if (order.lien?.qcFormTypeID && order.lien?.qcFormTypeID.toString() != "") {
      classReference = `${order.jurisdiction.toLowerCase()}-${order.lien!.qcFormTypeID!.toString().toLowerCase()}-${order.orderTypeID.toLowerCase()}-stepper`
    }

    if (order.orderStatusTypeID.toLowerCase() == "historicaldraft") {
      classReference = `historical-${classReference}`;
    }

    return classReference;
  }
}
